
.App {
  text-align: center;
  width: 100%;
  background-image: url("../src//assets//Banner-1.jpg");
  background-position: center;
  background-size: cover; /* This ensures the background covers the entire body */
  background-repeat: no-repeat;
  height: 110vh;
 

}

.body {
  /* background-image: url("data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw8NDRAPDw4NDw0NDg8PDQ0NDg8PDQ8PFREWFhURFRUYHSggGBolGxUVITEhJSkrLi4uFx8zODMsNygtLisBCgoKDQ0NDg0NDysZFRktKysrLTcrNzcrKy0rKys4LTc3KzcrLSsrNy0rKysrLTctNy0tLTc3KysrLSstKy0tN//AABEIAKgBLAMBIgACEQEDEQH/xAAXAAEBAQEAAAAAAAAAAAAAAAABAAIH/8QAIxABAQEAAQMEAwEBAAAAAAAAABEBITFBYQJRgaFxkcHwEv/EABYBAQEBAAAAAAAAAAAAAAAAAAEAAv/EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAwDAQACEQMRAD8A4aikUkQcBRiOA7+IUGsBMURwHMOJHBFCQcZhhPbp891pxmKGKI4It9MMUSxZ+M3jzx5EMUSxmKNJDGYo0EMZTU4/i7f06MZEaixDGd3ie3QNLt0+eb+EzjCaGkYEkWcCKSRRDUgKIakSKTUiMRDWA4Z3UBkERhTWBQpHEoYfTO+b04mzkHGUYolgRSWBGKJYII1ERjMRSGMpoRDGU0CMZRSZsZDQLNgDQLNjKKTOErDgbkBRDciKSakM++iRzA1ICiGsBKRwGFBrBETEcC3IUlgRiRwIpDGVGghg3/QNJDGQ0iMZDWgs4NDQTNjIaBZsAaBZsZRzYEzhKOJqG/XjMQIbhxGffRBqIpBqRFENSLEmt9O51zcuXLnXPdNYFmFBrEikcBKBwIxI4ymghgDSIxkNBDAGgWbAGgWbBuQEJmwBoFixkNAs0A7gLNan2sBBhIIbhw599gcDUO7f918oYcTcKRDURSDUKRDcRxJHEjEGsWrcIRwREIYA0CzgBRZoB0amaAUWaBrWTznTtWSxQDuAs0BoJigHQWaiCjCQQ1CRhwNw4WcKajXp9Xa7N6w9v4yQ1Ckg3CQQ1CsSDUJCTTQQR0hBCoHNW78eMLNFGpJmgHRpYqBBZq4nn3vEZILNC1K/osUC/E7pJmjRpBYqOA1KHCyQ1GkCm4SEGo0czrznGfvnsyQ1CQg3K0WUjrSCDWtIKo6UKt1HTuoBDSEkNQQLNpCBZtPq68c523oyaEzVoQLNQOrNm9M3xtn0WKyrsl2b1zsc43nPznRks1BAsVFf9bJ2tnkIStFkhuUkYg1K0WSmpWkyQ1rSB3J85Q1pNZSOtJk0NaSyktaTKR0oKoaQghpCRGoI+n1Ttm8bnPnJUzaAgRakqCzaggmLUCNLNqCBZtRZJZlJZIalaLOINStFkhqVpMlNa1mryzSDpNZSa1pL1S8Wdr1FR1pM1VLWkyktbz69+oFFS1oUVIaQElpCBZ0ioIad0BFm1BBM2kIFm07Jkt73OOvb3ARY0NTjNuc3jvn5ZSGkslHWkzSGpWiyk1rVLO6qDrSFSa1pVkg6aWUlrWZdnutZqqOtJmmpaUzVUtaooSGmoBDShRSNKFFQ0qhYWdW6ECzpCCGpJFkJJI39pJI1VIFU1JFFINJUpHVUUiKakEqqkjqqqSWqqpJaKqUgKqkRoSSSFSLKCSASSCxX76pEBJIP/9k="); */
  background-position: center;
  background-size: cover; /* This ensures the background covers the entire body */
  background-repeat: no-repeat;
  opacity: 0.8;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.body{
  background-image: url("https://stock.adobe.com/ie/images/vector-abstract-red-wavy-background-curve-flow-motion/413212498");
  background-position:"center",
}

.App-link {
  color: #61dafb;
}

.frame-selection {
  display: flex;
  flex-direction: column;
}

.camera {
  position: relative;
}

.photo-frame {
  position: relative;
}

.photo-frame-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  pointer-events: none; /* Prevent overlay from interfering with user interactions */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 768px) {
  .App{
    height: max-content;
    padding-bottom: 20px;
}
}